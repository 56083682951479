import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'layout/page/Page.layout.styled';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import {
  QuestionnaireContent,
  QuestionnaireFormIntro,
  QuestionnaireSlideWrapper,
} from 'pages/questionnaire/Questionnaire.styled';
import { FormFieldsGroup } from 'components/common/form/Form.styled';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import { Form, IActivities } from 'types/app';
import useFormHandler from 'hooks/useFormHandler';
import useAPIQuestionnaire from 'hooks/api/useAPIQuestionnaire';
import { getLocaleValue } from 'helpers/utils';
import { useSwiper } from 'swiper/react';
import CustomSelect from 'components/common/form/custom-select/CustomSelect';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import countriesI18n from 'i18n-iso-countries';
import enJson from 'i18n-iso-countries/langs/en.json';
import frJson from 'i18n-iso-countries/langs/fr.json';

countriesI18n.registerLocale(enJson);
countriesI18n.registerLocale(frJson);

const countryCodes = countriesI18n.getAlpha2Codes();
const countryList = Object.keys(countryCodes).map(code => ({
  en: countriesI18n.getName(code, 'en') || '',
  fr: countriesI18n.getName(code, 'fr') || '',
  code: code
}));

export interface IQuestionnaireProfileForm {
  activities: IActivities;
  currentActivity?: string;
  currentBirthYear?: string;
}

const getYearsList = (min: number, max: number): Array<Form.IOption> => {
  const yearsRange = Array.from({ length: max - min + 1 }, (_, index) => max - index);
  return yearsRange.map((year, index) => ({
    id: index,
    label: year.toString(),
    value: year.toString(),
  }));
};

const YEAR_MIN = 1920;
const YEAR_MAX = new Date().getFullYear() - 15;

const getCountriesList = (lang: 'fr' | 'en'): Array<Form.IOption> => {
  return countryList.map(((country, key) => {
    return {
      id: key,
      label: country[lang],
      value: country.code.toLowerCase()
    };
  }));
};

const QuestionnaireProfileForm: React.FC<IQuestionnaireProfileForm> = ({
  activities,
  currentActivity,
  currentBirthYear,
}) => {
  const { i18n, t } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const formEl = useRef<HTMLFormElement | null>(null);
  const parentSwiper = useSwiper();
  const { updateUserInformation } = useAPIQuestionnaire();
  const [yearsList, setYearsList] = useState<Array<Form.IOption>>();
  const [countriesList, setCountriesList] = useState<Array<Form.IOption>>(getCountriesList(i18n.language === 'fr' ? 'fr' : 'en'));

  useEffect(() => {
    setYearsList(getYearsList(YEAR_MIN, YEAR_MAX));
  }, []);

  useEffect(() => {
    setCountriesList(getCountriesList(i18n.language === 'fr' ? 'fr' : 'en'));
  }, [i18n.language]);

  const getYearFromList = (yearValue: string) => {
    return yearsList ? yearsList.find((year) => {
      return year.value == yearValue;
    }) : 0;
  };

  const getActivityFromList = (activityToSearch: string) => {
    const matchedActivity = activities.find(
      (activity) => activity.code[i18n.language] === activityToSearch,
    );
    if (matchedActivity) {
      const { code, label } = matchedActivity;
      const index = activities.findIndex((activity) => activity === matchedActivity);
      return { id: index, label: label[i18n.language], value: code[i18n.language] };
    }
    return null;
  };

  const [formFields, setFormFields] = useState<Array<Form.IField>>([]);
  const [birthYear, setBirthYear] = useState<string>(currentBirthYear || '');
  const [activity, setActivity] = useState<string>(currentActivity || '');
  const [country, setCountry] = useState<string | null>(user?.profileInfo?.nationality || 'fr');
  const [countryError, setCountryError] = useState<boolean | null>(null);

  useEffect(() => {
    setFormFields([
      {
        name: 'birth_year',
        options: yearsList,
        value: getYearFromList(currentBirthYear as string),
        required: false,
      },
      {
        name: 'activity',
        options: activities
          ? activities.map((activity, index) => ({
            id: index,
            label: getLocaleValue(activity.label, i18n.language),
            value: getLocaleValue(activity.code, i18n.language),
          }))
          : [],
        value: getActivityFromList(currentActivity as string) as Form.IValue,
        required: false,
      },
    ]);
  }, [yearsList, activities, countriesList, currentBirthYear, currentActivity]);

  const { getFieldByName } = useFormHandler(formFields);

  const formatFieldsPayload = () => {
    return {
      birth_year: birthYear,
      activity: activity,
      country: country
    };
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const payload = formatFieldsPayload();
    if (!payload.country) {
      setCountryError(true);
      return;
    }
    else {
      await updateUserInformation(payload.birth_year, `${payload.activity}`, `${payload.country}`);
      setTimeout(() => {
        parentSwiper.slideNext();
      }, 250);
    }
  };

  return (
    <QuestionnaireSlideWrapper>
      <QuestionnaireContent className="content">
        <PageTitle className='title-before-questionnaire-starts'>{t('questionnaire.before-start')}</PageTitle>
        <QuestionnaireFormIntro>{t('questionnaire.before-start-intro')}</QuestionnaireFormIntro>
        <div className="intro-form">
          <form noValidate onSubmit={onSubmit} autoComplete="off" ref={formEl}>
            {activities && (
              <FormFieldsGroup>
                <CustomSelect
                  options={getCountriesList((i18n.language === 'fr' ? 'fr' : 'en'))}
                  onChange={(e) => {
                    setCountry(e && e.value ? e.value : '');
                    setCountryError(false);
                  }}
                  label={t('common.country')}
                  required
                  showError={countryError}
                  defaultValue={country || ''}
                />
                <SelectBox
                  {...({
                    ...getFieldByName('birth_year', formFields),
                    label: t('common.year-of-birth'),
                    isNative: true,
                    onChange: (value?) => setBirthYear(value?.value || '')
                  } as SelectBoxProps)}
                />
                <SelectBox
                  {...({
                    ...getFieldByName('activity', formFields),
                    label: t('common.activity'),
                    onChange: (value?) => setActivity(value?.value || ''),
                    isNative: true,
                  } as SelectBoxProps)}
                  name="Selectbox"
                />
                <GenericDiv
                  className="submit-button"
                  minWidth={prem(100)}
                  maxWidth={prem(150)}
                  center
                >
                  <Button type="submit" fill="outline">
                    <span>{t('common.validate')}</span>
                  </Button>
                </GenericDiv>
              </FormFieldsGroup>
            )}
          </form>
        </div>
      </QuestionnaireContent>
    </QuestionnaireSlideWrapper>
  );
};

export default QuestionnaireProfileForm;
